const rIOS = /\(i[^;]+;( U;)? CPU.+Mac OS X/i;


const isIOS = () => {
  return rIOS.test(navigator.userAgent);
};

const isSafari=()=>{
  return /Version/.test(navigator.userAgent)&&/Safari/.test(navigator.userAgent)
}
// TODO 优化判断 window.screen.height > 812
const isIphoneX = () => {
  return (
    isIOS() &&
    window.devicePixelRatio &&
    window.devicePixelRatio === 3 &&
    window.screen.width === 375 &&
    window.screen.height === 812
  );
};

const isIphoneXR = () => {
  return (
    isIOS() &&
    window.devicePixelRatio &&
    window.devicePixelRatio === 2 &&
    window.screen.width === 414 &&
    window.screen.height === 896
  );
};

const isIphoneXSMax = () => {
  return (
    isIOS() &&
    window.devicePixelRatio &&
    window.devicePixelRatio === 3 &&
    window.screen.width === 414 &&
    window.screen.height === 896
  );
};

const isIphoneXSeries = () => {
  return isIphoneX() || isIphoneXR() || isIphoneXSMax();
};

const isAndroid = () => {
  return /android/i.test(navigator.userAgent);
};

const UA = {
  isIOS,
  isIphoneX,
  isIphoneXR,
  isIphoneXSMax,
  isIphoneXSeries,
  isAndroid,
  isSafari
};

export default UA;
